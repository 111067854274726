.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: transparent;
  background-color: rebeccapurple;
}

$modal_width: 680px;
.modal {
  //border: solid 2px #ffffff;
  left: 50%;
  position: absolute;
  top: 250px;
  transform: translate(-50%, 0);
  width: $modal_width;

  .modal-content {
    background-color: white;
    position: relative;
    width: 100%;

    .modal-header {
      height: 50px;
      background-color: #00c100;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      .close {
        position: absolute;
        right: 5px;
        padding: 10px 20px;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .wx-login {
        margin-top: 50px;
        height: 240px;
        width: 240px;
      }

      .description {
        color: #656565;
        font-size: 14px;
        line-height: 26px;
        margin-top: 28px;
        margin-bottom: 85px;
        text-align: center;
      }
    }
  }
}

