.page-container {
  margin: 0 auto;
  width: 1250px;
  //overflow: auto;
}

.beatmap-detail {
  width: 100%;
  height: 591px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 4px;

  .disc-container {
    left: 364px;
    position: absolute;
    top: 77px;
    $height: 432px;
    $width: 428px;
    $offset: 8px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: $height;
      width: $width;
      border-radius: 8569px;
    }

    *:first-child {
      position: relative;
      padding: $offset;
    }
  }
  img.cover {
    left: 71px;
    position: absolute;
    top: 71px;
    height: 449px;
    width: 449px;
  }

  div.info-container {
    left: 155px;
    position: absolute;
    top: 71px;
    height: 449px;
    width: 449px;

    .mask-backdrop {
      position: absolute;
      height: 449px;
      width: 449px;
      backdrop-filter: blur(10px);
      background-color: rgba(#310f43, 0.2);
    }

    .info-blocks {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 449px;
      width: 449px;
      padding: 20px;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 26px;
      color: #ffffff;
      margin-bottom: 12px;

      & p, span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      p.title {
        max-width: 100%;
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 36px;
      }
      p.beatmap-length {
        margin: 20px 0;
      }
      p.desc {
        flex: auto;
        white-space: normal;
      }
      p.tags {
        opacity: 0.5;
        margin-bottom: 25px;
      }
      .button-group {
        display: flex;

        & button {
          height: 50px;
          border: solid 2px #a24cff;
          background-color: transparent;
          min-width: 110px;
          border-radius: 8569px;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 36px;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 0.9;

          &:not(:last-child) {
            margin-right: 15px;
          }

          &:hover {
            background-color: #a24cff;
          }
        }
      }
    }
  }

  div.play-info-container {
    display: grid;
    grid-row-gap: 40px;
    grid-template-columns: repeat(2, 126px);
    justify-content: space-between;
    width: 286px;
    right: 70px;
    position: absolute;
    top: 150px;

    .play-info-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 126px;
      width: 126px;
      background-image: url("../images/play_info_item_bg.png");

      .inner-container {
        font-weight: normal;
        font-stretch: normal;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .value {
          font-size: 26px;
          line-height: 36px;
          opacity: 0.7;
        }

        .name {
          font-size: 12px;
          line-height: 20px;
          opacity: 0.5;
        }
      }
    }
  }

  .diffs-container {
    position: absolute;
    top: 109px;
    left: 1260px;
    background-color: rgba(#ffffff, 0.04);
    border-radius: 10px;

    button {
      display: block;
      position: relative;
      width: 116px;
      height: 76px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 26px;
      letter-spacing: 0;
      color: #ffffff;
      background-color: transparent;
      border: none;
      margin-top: 2px;

      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &:not(:last-child)::after{
        content: " ";
        position: absolute;
        width: 50%;
        bottom: 0;
        left: 25%;
        margin-bottom: -2px;
        border-bottom: solid 2px gray;
      }

      &:hover {
        background-color: rgba(#a653ff, 0.4);
      }

      &.active {
        background-color: #a653ff;
      }
    }

  }
}

.rank-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: solid 1px #3e1c60;
  margin-top: 25px;
  align-items: center;

  .row {
    align-items: center;
    height: 40px;
    display: flex;
    width: 100%;
    justify-content: center;
    &:nth-of-type(2n+1) {
      background-color: rgba(#ffffff, 0.02);
    }

    &.header {
      height: 50px;
      background-color: #351752;
    }

    .item {
      text-align: center;
      flex: 1;
      &.extra {
        flex: 2;
      }
    }
  }
  .empty-records {
    line-height: 60px;
    height: 60px;
    text-align: center;
  }


}
