$margin: 14px;
$image-size: 190px;

div.beatmap-result {
  $hide-cover: 750px;

  $radius: 8px;
  margin-bottom: $margin;
  border-radius: $radius;
  display: flex;

  $transition-time: 120ms;
  box-shadow: rgba(0, 0, 0, 0.4) 0 4px 6px 0px;
  transition: transform $transition-time ease, box-shadow $transition-time ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, 0.6) 0 6px 10px 0px;
  }

  & div.cover {
    border-radius: $radius 0 0 $radius;
    width: $image-size;
    height: $image-size;
    overflow: hidden;

    @media screen and (max-width: $hide-cover) {
      width: 0;
    }

    & img {
      width: $image-size;
      height: $image-size;
      object-fit: cover;

      border-radius: $radius 0 0 $radius;
    }
  }

  & div.beatmap-content {
    $content-padding: 8px;

    flex: 1;
    border-radius: 0 $radius $radius 0;
    background-color: rgba(255, 255, 255, 0.92);
    padding: $content-padding;

    @media screen and (max-width: $hide-cover) {
      border-radius: $radius;
    }

    display: flex;

    & .right {
      display: flex;
      flex-direction: column;

      & .stats {
        flex: 1;

        text-align: right;
        color: rgb(23, 23, 24);
      }

      & .is-button-group {
        margin: -3px -8px;
      }

      & a, button {
        color: $text;
        padding: 6px 18px;

        position: relative;
        margin-right: -5;

        $border: 1px solid rgba(0, 0, 0, .1);
        border-top: $border;
        border-left: $border;

        background-color: rgba(255, 255, 255, .4);
        transition: background-color 100ms ease;

        &:last-child {
          border-radius: 0 0 $radius 0;
        }

        &:hover {
          background-color: rgba(0, 0, 0, .05);
        }

        &:active {
          background-color: rgba(0, 0, 0, .1);
        }

        &.loading {
          color: transparent;

          &::after {
            content: '';
            position: absolute;
            left: calc(50% - (1em / 2));
            top: calc(50% - (1em / 2));

            display: block;
            width: 1em;
            height: 1em;

            border: 2px solid $text;
            border-radius: 100%;
            border-right-color: transparent;
            border-top-color: transparent;

            animation: spinAround .5s infinite linear;
          }
        }

        &.disabled {
          pointer-events: none;
        }
      }
    }

    & .outer {
      flex: 1;

      display: flex;
      flex-direction: column;

      & div.details {
        flex-grow: 1;
        margin-bottom: $content-padding;

        color: rgb(23, 23, 24);

        & h1, h2 {
          line-height: 1em;
        }

        & h1 {
          font-size: 1.8rem;
        }

        & h2 {
          font-size: 1.15rem;
          margin-top: 6px;

          & > span.uploaded {
            color: rgba(0, 0, 0, 0.5);
            font-size: 0.7em;
          }
        }

        @media screen and (max-width: $hide-cover) {
          & h1 {
            font-size: 1.6rem;
          }

          & h2 {
            font-size: 1rem !important;
          }
        }
      }
    }
  }
}

div.beatmap-result-hidden {
  height: $margin + $image-size;
}
