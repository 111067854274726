div.thin {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  .input {
    background-color: #171718;
    color: #eff1f5;
    box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
    max-width: 100%;
    width: 100%;
    padding-left: 2.25em;
    height: 36px;
    border: 1px solid #2d2d2f;
    border-radius: 4px;
  }

  span{
    font-style: inherit;
    font-weight: inherit;
    font-size: 1rem;
    text-align: left;
  }

  .control{
    display: block;
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: left;
  }

  .icon {
    left: 0;
    color: #dbdbdb;
    height: 2.25em;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 2.25em;
    z-index: 4;
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
  }
}
