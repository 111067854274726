::-webkit-scrollbar {
  display: block;
}

.head-container {
  position: relative;
  top: 0;
  overflow: hidden;
  width: 1920px;
  height: 1080px;

  .player-wrapper-as-bg {
    position: absolute;
    top: 0;
  }

  .top-interactive-layer {
    background-color: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .shit-design {
      position: relative;
      top: 0;
      margin: 330px auto 0 auto;
      width: 1321px;
      height: 364px;

      img {
        position: absolute;
        top: 0;
        margin: 0 auto;
      }

      .sub-line {
        position: absolute;
        top: 300px;
        color: #ffffff;
        font-family: SourceHanSansCN-Medium;
        font-size: 30px;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.download {
  display: block;
  background-color: transparent;
  border-radius: 8569px;
  border: solid 4px #ffffff;
  margin: 54px auto 0 auto;
  box-shadow: none;
  color: #ffffff;
  font-size: 30px;
  padding: 20px 70px;

  &:hover,
  &:focus {
    background-color: blue;
  }

  &:active {
    background-color: red;
  }
}

.steps-container {
  display: flex;
  margin: 90px 400px 50px 400px;
  justify-content: space-around;
  color: #ffffff;

  .seq-container {
    margin: 0;
    padding: 0;
    height: 94px;

    .seq {
      border: solid 2px #ffffff;
      border-radius: 14px;
      display: block;
      float: left;
      font-size: 40px;

      margin: 0;
      width: 100px;
      text-align: center;
      line-height: 100px;
    }

    .lines-container {
      float: left;
      max-width: 187px;
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .line1 {
        font-size: 22px;
        line-height: 30px;
        margin: 0 0 20px 0;
      }
      .line2 {
        font-size: 1em;
        line-height: 24px;
        margin: 0;
      }
    }

  }
}

.div-outer {
  background-image: url("../images/video_bg.png");
  width: 100%;
  padding-top: 80px;
  background-repeat: no-repeat;

  .player-wrapper {
    margin: 0 auto;
    height: 670px;
    width: 1198px;

    .react-player {
      border: solid;
      border-width: 0 10px;
      border-left-color: red;
      border-right-color: blue;

    }
  }
}


