// Loading.io double-ring spinner
// https://loading.io/spinner/double-ring
// CC BY License
// CSS Class names have been modified

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner_reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.loader-spinner {
  position: relative;

  & div {
    position: absolute;
    width: 180px;
    height: 180px;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    border: 10px solid #000;
    border-color: #b8b9c2 transparent #b8b9c2 transparent;
    animation: spinner 1.7s linear infinite;

    &:nth-child(2) {
      width: 156px;
      height: 156px;
      top: 22px;
      left: 22px;
      border-color: transparent #9292a0 transparent #9292a0;
      animation: spinner_reverse 1.7s linear infinite;
    }
  }

  width: 120px !important;
  height: 120px !important;
  transform: translate(-60px, -60px) scale(0.4) translate(60px, 60px);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
