.navbar {
  width: 100%;
  height: $navbar-height;
  background-color: #030515;
  opacity: 0.8;
  z-index: 200;

  &.not-index {
    background-color: $bg;
    opacity: 1;
  }

  a {
    color: $tex;
    text-decoration: none;
  }

  .container {
    margin: 0 142px;
    display: flex;
    align-items: center;

    .navbar-start {
      overflow: auto;
      flex: none;

      & img {
        width: 254px;
        height: $navbar-height;
      }
    }

    .navbar-tabs {
      flex: auto;
      font-size: 1.25em;
      display: flex;
      justify-content: center;
      align-items: center;

      .navbar-tab-item {
        margin: 0 60px;
        min-width: 80px;
        text-align: center;
        opacity: 0.9;
        .navbar-item {
          min-height: 0;
        }

        & a {
          display: block;
          color: $tex;
          text-decoration: none;
        }

        & hr {
          height: 3px;
          width: 100%;
          margin: 10px 0 0 0;
          background-color: transparent;
          border: none;

          &.active {
            background-color: #368bff;
          }
        }
      }
    }

    .navbar-end {
      overflow: visible;
      display: flex;
      align-items: center;
      flex: none;

      button {
        background-color: #368bff;
        border-radius: 8569px;
        border: none;
        box-shadow: none;
        color: #ffffff;
        font-size: 1em;
        padding: 10px 20px;
        margin: 0 4px;
      }
    }
  }

  .navbar-item {
    cursor: pointer;
    min-height: $navbar-height * 1 / 2;
    display: block;
  }

  .has-dropdown {
    position: relative;
    min-width: 160px;
    height: $navbar-height;
    display: flex;
    align-items: center;
    justify-content: center;

    > *:first-child {
      display: flex;
      align-items: center;

      &::after {
        content: "\25BC";
        margin-left: 12px;
        color: #ffffff;
      }
    }
  }

  .navbar-dropdown {
    position: absolute;
    margin-top: -2px;
    border: none;
    top: $navbar-height;
    display: none;
    background-color: #030515;
    overflow: auto;

    .navbar-item {
      min-width: 160px;
      height: 74px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .is-hoverable:hover {
    > *:last-child {
      display: block;
    }
  }

  .navbar-img {
    width: 60px;
    height: 60px;
    border-radius: 8569px;

    &:hover {
      opacity: 0.8;
    }
  }
}
