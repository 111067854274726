html {
  background-color: $bg;
  color: $tex;
}

html, body, div#root {
  overflow: auto;
  height: 100%;
  //margin-right: calc(100% - 100vw);
  ::-webkit-scrollbar {
    display: none;
    width: 1em;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

@media screen and (max-width: 1023px) {
  div.container.side-pad {
    width: 100%;
    padding: 0 20px;
  }
}

div.layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  .container {
    flex: auto;
  }

  &.not-index {
    margin-top: $navbar-height;
  }
}

div.container.has-footer {
  flex-grow: 1;
}

footer.sticky-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 50px;
  margin-top: 30px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 0px;
  font-size: 14px;
  background-color: #0c0c0c;
  color: #9c9c9c;

  ul {
    font-size: 16px;

    li {
      display: inline-block;
      list-style-type: none;
      text-align: center;

      a {
        display: block;
        text-decoration: none;
        color: #9c9c9c;
        vertical-align: center;

        &:hover {
          color: #ffffff;
        }
      }
      &:not(:last-child) {
        a:after {
          content: ' ';
          font-size: 12px;
          border-left: solid 2px #9c9c9c;
          line-height: 26px;
          vertical-align: center;
          margin: 0 12px;
        }
      }
    }

  }

  .line2 {
    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: #ffffff;
      }
    }
    span:last-child {
      margin-left: 16px;
    }
  }

}

div.container.pad {
  margin-top: 24px;
  margin-bottom: 36px;
}

div.thin {
  max-width: 750px;
  width: 100%;
  margin: 30px auto 0 auto;

  &>p {
    margin-bottom: 10px;
  }
}

.is-fullwidth {
  width: 100%;
}
