@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.upload {
  margin-top: 100px;

  h1 {
    width: 80px;
    height: 20px;
    font-size: 20px;
    color: #ececec;
    opacity: 0.9;
  }

  span {
    font-style: inherit;
    font-weight: inherit;
  }

  .field {
    margin-bottom: 1rem;
    font-size: 0.8em;
  }

  .is-fullwidth, div.thin {
    width: 100%;
  }

  .label {
    margin-bottom: 1em;
    font-size: 0.8em;
    display:block;
  }

  .input {
    background-color: #171718;
    border: 1px solid #2d2d2f;
    max-width: 100%;
    width: 100%;
    height: 36px;
    padding: 0.625em;
    color: #eff1f5;
  }

  .textarea {
    background-color: #171718;
    border: 1px solid #2d2d2f;
    max-width: 100%;
    width: 100%;
    height: 120px;
    color: #eff1f5;
    padding: 0.625em;
  }

  .file-input {
    height: 100%;
    left: 0;
    opacity: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .file {
  }

  .file-name {
    border: 1px solid #dbdbdb;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    flex-grow: 1;
    border-radius: 4px;
    line-height: 1.5;
    padding: 0.5em;
    height: 2.25em;
    margin-left: 0.5em;
  }

  .file-cta {
    background-color: #f5f5f5;
    color: #4a4a4a;
    border-radius: 4px;
    padding: 0.5em;
    position: relative;
    display: inline-flex;
    height: 2.25em;
  }

  .file-icon {
    margin-right: 0.5em;
  }

  .file-label {
    display: flex;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .button {
    background-color: #36373a;
    border-width: 0;
    color: #eff1f5;
    cursor: pointer;
    padding: 0.375em 0.75em;
    text-align: center;
    height: 2.25em;
    border-radius: 4px;

    &.is-loading {
      position: relative;
      opacity: 0.5;
      color: transparent!important;
      pointer-events: none;

      &:after {
        top: calc(50% - 0.5em);
        left: calc(50% - 2.5em);
        width: 1em;
        height: 1em;
        -webkit-animation: spinAround .5s linear infinite!important;
        animation: spinAround .5s linear infinite!important;
        border-radius: 290486px;
        border-color: transparent transparent #dbdbdb #dbdbdb;
        border-style: solid;
        border-width: 2px;
        content: "";
        display: block;
        position: absolute;
      }
    }
  }

  .content ul {
    list-style: disc outside;
    margin-left: 2em;
    margin-top: 1em;
  }

  .agreement{
    margin-top: 0.5em;
    text-align: center;
  }
}
