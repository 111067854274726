.mono {
  font-family: 'Fira Code', 'Fira Mono', monospace;
}

.swal-validation-content p:not(:last-child) {
  margin-bottom: 14px;
}

.license  {
  text-align: justify;

  & p:not(:last-child) {
    margin-bottom: 14px;
  }
}
