$item-height: 300px;

div.beatmap-grid-result {
  height: $item-height;
  //background-color: black;

  .beatmap-content-container {
    width: 100%;
    height: 100%;
    position: relative;

    > img {
      width: 294px;
      height: 294px;
      position: absolute;
      right: 0;
      top: 0;
    }
    .beatmap-info-container {
      background-color: white;
      border-radius: 4px;
      overflow: hidden;
      height: $item-height;
      position: absolute;
      left: 0;
      top: 0;
      width: $item-height;
      .crazy-cover-back {
        position: absolute;
        left: 0;
        top: 0;
        img {
          width: $item-height;
          height: $item-height;
          object-fit: cover;
        }
        .backdrop {
          width: $item-height;
          height: $item-height;
          backdrop-filter: blur(10px);
          position: absolute;
          left: 0;
          top: 0;
        }
        .mask {
          width: $item-height;
          height: $item-height;
          position: absolute;
          left: 0;
          top: 0;
          background-color: #310f43;
          opacity: 0.2;
        }
      }
      .crazy-cover-top {
        position: absolute;
        left: 8px;
        top: 8px;
        width: 284px;
        height: 206px;
        object-fit: cover;
      }
      .tags {
        position: absolute;
        left: 12px;
        top: 12px;
        span {
          display: block;
          width: 68px;
          height: 26px;
          font-size: 14px;
          line-height: 26px;
          text-align: center;
          background-color: #1a062a;
          border-radius: 6px;
          opacity: 0.4;
          margin-bottom: 4px;
        }
      }

      #play-icon {
        position: absolute;
        right: 12px;
        top: 12px;
      }

      .bottom-container-1 {
        position: absolute;
        left: 15px;
        bottom: 0;
        right: 15px;
        .beatmap-name {
          max-width: 284px;
          font-size: 22px;
          font-weight: normal;
          line-height: 36px;
          color: #ffffff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .uploader-name {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 26px;
          color: #ffffff;
          margin-bottom: 15px;;
        }
      }

      .bottom-container-2 {
        position: absolute;
        height: 180px;
        width: 300px;
        left: 0;
        bottom: 0;
        background-image: url('../images/beatmap_hover.png');
        .beatmap-name {
          position: static;
          height: 93px;
          margin-left: 17px;
          margin-top: 26px;
          max-lines: 3;
          font-size: 22px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 36px;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .tags {
          position: static;
          margin-left: 17px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 26px;
          letter-spacing: 0px;
          color: #cecece;
        }
      }

    }
  }
}

div.beatmap-grid-result-hidden {
  height: $item-height;
}
