// Override Bulma variables
$bg: #230E37;
//$bg: #ff0000;
$tex: #ffffff;
$btn: #36373a;
$main: #6782ff;
$text: $tex; //for compile ok...

// Alternate Colours
$alt: $main;
$altDark: darken($alt, 10);

// Override Computed
$primary: $main;
$primary-invert: findColorInvert($primary);
$dark: lighten($bg, 8);
$dark-invert: findColorInvert($dark);


$navbar-height: 99px;


.backdrop {
  backdrop-filter: blur(5px);
}

.fix-top {
  position: fixed;
  top: 0;
}

button {
  outline: none;
}

.hidden {
  display: none;
}

img {
  object-fit: cover;
}
