div.wx-login{
  align-content: center;
  vertical-align: center;

  & div {
    margin: 0 auto;
  }

  & iframe {
    width: 100%;
    height: 600px;
  }
}
