.detail-artwork {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;

  width: 100%;
  height: 400px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.01);

    filter: blur(5px) saturate(0.9) brightness(0.8);
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    );

    user-select: none;
  }
}

.detail-content {
  margin: 0 48px;

  @media screen and (max-width: 1023px) {
    margin: 0;
  }

  & h1, h2 {
    color: white;
    text-shadow: 0 1px 3px rgba(0, 0, 0, .9);

    & a {
      color: lighten($main, 15);

      &:hover {
        color: lighten($main, 17);
      }
    }
  }

  & h1 input {
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    text-shadow: inherit;
    width: 100%;

    outline: none;
    border: 1px dashed white;
    border-radius: 5px;

    text-decoration: underline;
    padding: 4px 8px;
  }

  & h2 {
    margin-bottom: 18px;
  }

  & .box {
    color: rgb(23, 23, 24);

    margin-top: 0;
    margin-bottom: 0;

    display: flex;
    flex-wrap: wrap-reverse;

    &.has-buttons-top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.has-buttons-bottom {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & .left {
      flex: 1;
      display: flex;
      flex-direction: column;

      & .metadata {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 12px;

        & .col {
          &:not(:last-child) {
            margin-right: 1.5em;
          }
        }

        & td {
          vertical-align: baseline;
          margin-bottom: 4px;

          &.hidden {
            opacity: 0.3;
          }

          &:first-child {
            padding-right: 0.7em;

            color: rgba(0, 0, 0, 0.5);
            text-transform: uppercase;
            font-size: 0.7em;
          }
        }
      }

      & .description {
        flex-grow: 1;
        margin-bottom: 12px;

        & textarea {
          font-size: 1rem;
          resize: none;
          width: 100%;

          padding: 8px;
          border: 1px dashed currentColor;
          border-radius: 5px;
          outline: none;
        }
      }
    }

    & .right {
      margin-left: 20px;
      text-align: right;
    }
  }

  & .buttons {
    $border: 1px solid rgba(0, 0, 0, .1);

    width: 100%;
    display: flex;

    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    background-color: white;
    transition: background-color 100ms ease;

    box-shadow:
    rgba(10, 10, 10, 0.1) 0px 2px 3px,
    rgba(10, 10, 10, 0.1) 0px 0px 0px 1px;

    &.top {
      border-radius: 6px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:not(:last-child) {
        margin-bottom: 0;
      }

      & a, button {
        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 0;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 0;
        }
      }
    }

    & button {
      appearance: none;
      border: none;
      background-color: transparent;
      border-radius: 0;
      outline: none;

      font-size: 1rem;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, .05);
      }

      &:active {
        background-color: rgba(0, 0, 0, .1);
      }
    }

    & a, button {
      color: $text;

      padding: 14px 20px;
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;

      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-bottom-right-radius: 6px;
      }

      &:not(:last-child) {
        border-right: $border;
      }

      &:hover {
        background-color: rgba(0, 0, 0, .05);
      }

      &:active {
        background-color: rgba(0, 0, 0, .1);
      }

      &.loading {
        color: transparent;

        &::after {
          content: '';
          position: absolute;
          left: calc(50% - (1em / 2));
          top: calc(50% - (1em / 2));

          display: block;
          width: 1em;
          height: 1em;

          border: 2px solid $text;
          border-radius: 100%;
          border-right-color: transparent;
          border-top-color: transparent;

          animation: spinAround .5s infinite linear;
        }
      }

      &.disabled {
        pointer-events: none;
      }
    }
  }
}
