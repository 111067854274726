span.tag.is-easy {
  color: white;
  background-color: #3cb371;
}

span.tag.is-normal {
  color: white;
  background-color: #59b0f4;
}

span.tag.is-hard {
  color: white;
  background-color: #ff6347;
}

span.tag.is-expert {
  color: white;
  background-color: #bf2a42;
}

span.tag.is-expert-plus {
  color: white;
  background-color: #8f48db;
}
