$margin: 2px;
$image-size: 80px;

div.beatmap-my-result {
  background-color: #391B56;
  $hide-cover: 750px;

  $radius: 4px;
  margin-bottom: $margin;
  border-radius: $radius;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;

  $transition-time: 120ms;
  box-shadow: rgba(0, 0, 0, 0.4) 0 4px 6px 0px;
  transition: transform $transition-time ease, box-shadow $transition-time ease;

  & div {
    height: 100%;
    width: 180px;
    display: inline-grid;
    text-align: center;
    align-content: center;
  }

  & div.fix-width {
    width: 120px;
  }

  &:hover {
    //transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, 0.6) 0 6px 10px 0px;
    background-color: #49246C;
  }

  & div.cover {
    flex-shrink: 0;
    border-radius: $radius 0 0 $radius;
    width: $image-size + 20;
    height: $image-size;
    overflow: hidden;

    //@media screen and (max-width: $hide-cover) {
    //  width: 0;
    //}

    & img {
      width: $image-size;
      height: $image-size;
      object-fit: cover;

      border-radius: $radius 0 0 $radius;
    }
  }

  & a {
    color: #ffffff;
    display: block;
    width: 100px;
    text-decoration: none;
  }


  .right {
    & a {
      height: 1.5em;
      margin-top: 2px;
    }
  }
}

div.beatmap-result-hidden {
  height: $margin + $image-size;
}

div.beatmap-my-result:nth-of-type(1) {
  height: 50px;
  //position: fixed;

  &:hover {
    background-color: #391B56;
  }
}
