@import "_beatmap-my-result";

.uploader {
  //margin-top: 100px;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #411b65;
    height: 50px;
    margin-top: 40px;

    & a {
      display: block;
      color: $tex;
      text-decoration: none;
    }

    .selected {
      background-color: #5d3d81;
      border-radius: 4px;
    }

    span {
      height: 100%;
      width: 180px;
      display: inline-grid;
      //width: 10em;
      //height: 2.5em;
      background-color: #411b65;
      text-align: center;
      align-content: center;
      min-width: 150px;
    }

    span:hover {
      background-color: #5d3d81;
      border-radius: 4px;
    }

  }

  .list {
    margin-top: 15px;
    max-width: 1250px;
    justify-content: center;
    align-items: center;

    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #351752;
      height: 50px;
      border-radius: 4px;
      margin-bottom: 2px;

      span {
        height: 100%;
        width: 180px;
        display: inline-grid;
        text-align: center;
        align-content: center;
      }

      .double-left {
        flex-shrink: 0;
      }
    }
  }
}
