.content-container {
  height: 100%;
  margin: 0 auto;
  padding-top: 28px;
  width: 1244px;

  .grid-container {
    display: grid;
    grid-row-gap: 30px;
    //grid-row-gap: 300px;
    grid-template-columns: repeat(3, 375px);
    justify-content: space-between;

    width: 100%;
  }

  .beatmaps-empty {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 200px;
    font-size: 40px;
  }
}

